import React from 'react'
import { useEffect } from 'react'
import Layout from './Layout'
import Seo from './Seo'
import { fireEvent } from '../analytics'

const SculptingChallengeThanksTemplate = ({ data }) => {
  useEffect(() => {
    fireEvent('Lead')
  }, [])
  return (
    <div className='hero has-navbar-fixed-top'>
      <div className='hero-body'>
        <div className='container content'>
          <p>{data.text1}</p>
          {data.text1Done && <h2>{data.text1Done}</h2>}
          {data.callToActionDoneLink && (
            <p className='is-flex justify-center'>
              <a
                href={data.callToActionDoneLink}
                className='button is-nude is-flex items-center justify-start'
                target='_blank'
                rel='noreferrer'
              >
                {data.callToActionDone}
              </a>
            </p>
          )}
          <h2>{data.header}</h2>
          <p>{data.text2Done || data.text2}</p>
          <p className='is-flex justify-center'>
            <a
              href={data.callToActionLink}
              className='button is-nude is-flex items-center justify-start'
              target='_blank'
              rel='noreferrer'
            >
              {data.callToAction}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

const SculptingChallengeThanks = ({
  pageContext: data,
  location: { pathname }
}) => {
  return (
    <Layout>
      <Seo
        metadata={data.metadata}
        pathname={pathname}
        robots='noindex, nofollow'
      />
      <SculptingChallengeThanksTemplate data={data} />
    </Layout>
  )
}

export default SculptingChallengeThanks
